.router-button.MuiButton-root {
    color: #333333;
    text-transform: none;
    font-weight: 300;
    font-size: 12pt;
    text-align: center;
    &:hover {}
    &:active {}
    &:focus {}
    &.active-nav {
        font-weight: 500;
    }
}
.MuiButton-root.tile-button.appointment-button {
    background-color: #f5d8c1;
    &:hover {}
    &:active {}
    &:focus {}
    &.active-nav {
        font-weight: 500;
    }
}

.MuiButton-root.MuiButton-text.contact-button {
    color: #575757;
    text-transform: none;
    background-color: #f5d8c1;
    font-weight: 300;
    font-size: 12pt;
    text-align: center;
    &:hover {}
    &:active {}
    &:focus {}
    &.active-nav {
        font-weight: 500;
    }
    font-size: 16px;
    font-weight: 400;
    margin-left: auto !important;
    margin-right: auto !important;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 5px;
    text-align: center;
}


.right {
    margin-left: auto !important;
}

.MuiAppBar-root.top-nav {
    background-color: #fffefb;
}

.nav-drawer:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border: none;
    background-image: url('./media/journey_field.jpg');
    mask-image: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0.5), rgba(0,0,0,0) 50%);
}

.MuiButton-root.tile-button {
    color: #333333;
    height: 100%;
    width: 100%;
    max-width: 240px;
    max-height: 60px;
    font-size: 16px;
    font-weight: 400;
    padding: 22px;
    border-radius: 10px;
    margin-left: auto !important;
    margin-right: auto !important;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 5px;
    text-align: center;
}
